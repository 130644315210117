import {moreUser} from './more_user'
import { UserModule } from '@/store/modules/user';
// User
const refreshTokenKey = 'issue-refresh-token';
const loginTimeKey = 'LoginTime';
const expiresInKey = 'expiresIn'
const angentKey = 'agentId';
const staffKey = 'staffId';
const isModifyPs ='isModifyPs';
export const getAgentId = () => sessionStorage.getItem(angentKey) || '';
export const setAgentId = (id: string) => sessionStorage.setItem(angentKey, id);
export const removeAgentId = () => sessionStorage.removeItem(angentKey);

export const setRefreshToken = (token: any) => sessionStorage.setItem(moreUser(refreshTokenKey), token);
export const getRefreshToken = () => sessionStorage.getItem(moreUser(refreshTokenKey)) || '';
export const removeRefreshToken = () => sessionStorage.removeItem(moreUser(refreshTokenKey));

export const setExpiresIn = (expiresIn: string) => sessionStorage.setItem(expiresInKey, expiresIn);
export const getExpiresIn = () => sessionStorage.getItem(expiresInKey) || '';
export const removeExpiresIn = () => sessionStorage.removeItem(expiresInKey);

export const setLoginTime = (expiresIn: string) => sessionStorage.setItem(loginTimeKey, expiresIn);
export const getLoginTime = () => sessionStorage.getItem(loginTimeKey) || '';
export const removeLoginTime = () => sessionStorage.removeItem(loginTimeKey);

export const getStaffId = () => sessionStorage.getItem(staffKey) || '';
export const setStaffId = (id: string) => sessionStorage.setItem(staffKey, id);
export const removeStaffId = () => sessionStorage.removeItem(staffKey);

// 是否需要强制修改密码
export const getModifyPs = () => sessionStorage.getItem(isModifyPs);
export const setModifyPs = (bool: any) => sessionStorage.setItem(isModifyPs, bool);
export const removeModifyPs = () => sessionStorage.removeItem(isModifyPs);