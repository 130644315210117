import router from './router';
import NProgress from 'nprogress'; // Progress 路由进度条
import 'nprogress/nprogress.css';
import { UserModule } from '@/store/modules/user';
import { Route } from 'vue-router';
import {getModifyPs} from '@/utils/storage_session'
const whiteList = ['/login']; // 不重定向白名单
let flag = false; // 刷新页面时仅调用一次
router.beforeEach(async (to: Route, _: Route, next: any) => {
  NProgress.start();
    // bms携带currentTimeMillis（时间戳）、userId（用户ID）、userPwd（用户临时密钥）
  if (to.query && !isNaN(Number(to.query.id)) && to.query.id && to.query.p && to.query.tmillis && to.query.fkCompanyId) { // bms一键进入新issue
    try {
      const params = {
        username: Number(to.query.id),
        password: to.query.p,
        currentTimeMillis: to.query.tmillis,
        fkCompanyId: to.query.fkCompanyId,
        bmsLoginId: to.query.bmsLoginId
      }
      await UserModule.BmsAgentLogin(params);
      if (to.query.type === 'viewform' && to.query.stuid && !isNaN(Number(to.query.stuid))) {
        next({ name: 'StudentApplicationForm', params: { stuid: to.query.stuid } });
      } else {
        next({ path: '/index' });
      }
      NProgress.done();
    } catch (err) {
      next('/login');
    }
  // bms一键创建新issue改变方式,不需要如下方式：携带邀请码让用户自行注册
  // else if (to.query && to.query.type === 'create' && to.query.invitcode) { // bms一键创建新issue账号
  //   await UserModule.ResetToken();
  //   next({ path: '/login', query: { invitcode: to.query.invitcode } });
  //   NProgress.done();
  // }
  } else if (UserModule.token) {
    if (!flag) {
      await UserModule.GetIssueRoot();
      flag = true;
      // 解决在addRoute()之后第一次访问被添加的路由会白屏，这是因为刚刚addRoutes()立刻访问被添加的路由，
      // 然而此时的addRoute()没有执行结束，因而找不到刚刚被添加的路由导致白屏。因此需要重新访问一次路由使用next({ ...to, replace: true }); 可确保路由被添加成功。
      next({ ...to, replace: true });
    } else {
      next();
    }
    if (to.path === '/login') {
      next({ path: '/index' });
      NProgress.done();
    } else if(to.path !== '/reset') {
      // 如果检测到强制修改密码，则跳转重置密码页面
      if(getModifyPs()==='true'){
        next({ path:'/reset',replace:true});
        NProgress.done();
      }else{
        next();
        NProgress.done();
      }
    }else{
      next();
      NProgress.done();
    }
  } else {
    flag = false; // 退出时清掉
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      NProgress.done();
    }
  }
  NProgress.done();
});

router.afterEach(() => {
  NProgress.done();
});
